import select2 from 'select2';
// Init select2
select2();
export const productWidthSelect = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
    $select.on('change', function (e) {
        var data = $(e.currentTarget).select2("val");
        Livewire.dispatch('setWidth', {widthId: data});
    });
}

export const productFinishSelect = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
    $select.on('change', function (e) {
        var data = $(e.currentTarget).select2("val");
        Livewire.dispatch('setColor', {colorId: data});
    });
}

export const downloadWidthSelect = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
    $select.on('change', function (e) {
        var data = $(e.currentTarget).select2("val");
        Livewire.dispatch('setWidth', {widthId: data});
    });
}

export const downloadFinishSelect = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
    $select.on('change', function (e) {
        var data = $(e.currentTarget).select2("val");
        Livewire.dispatch('setColor', {colorId: data});
    });
}

export const countrySelect = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}

export const selectCountry_2 = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}
export const selectCountry_3 = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}

export const selectCountry_4 = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}

export const selectProvince = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}

export const selectProvince_2 = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}

export const selectProvince_3 = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}

export const selectCity = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}

export const selectCity_2 = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}

export const selectCity_3 = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}

export const selectUserType = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}

export const selectRequestType = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}

export const modalProductType = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}

export const modalProductCode = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}


