import {ReadMore} from "../readMore";
import {debounce} from "../debounce";
import {matchMediaXlUp} from "../mediaQuery";
import {StoreLocator} from "../storeLocator";

export default function productList() {
    document.addEventListener('livewire:init', () => {
        Livewire.on('hide-spiders', (event) => {
            setTimeout(() => {
                $('.product-card .lazy').removeClass('lazy');
                // $('.spider-pagination-products').hide();
                ReadMore.init();
            }, 500)
        });

        Livewire.on('$refresh', (params) => {
            $('#color-attributes input').not('[value=' + params[0] + ']').prop('checked', false);
            $('#width-attributes input').not('[value=' + params[1] + ']').prop('checked', false);
            $('#finish-attributes input').not('[value=' + params[2] + ']').prop('checked', false);
            $('#version-attributes input').not('[value=' + params[3] + ']').prop('checked', false);
        });

        Livewire.on('updateFiltersCount', (count) => {
            $('#filtersCount').text(count)
        });

        Livewire.on('searchProducts', (params) => {
            $('#filtersClose').click();
        });

        Livewire.on('searchListEventAnalytics', (params) => {
            let data = params[0];
            data['event'] = 'ga4_event';
            const event = new CustomEvent('DataLayerEventPush', {
                detail: {
                    data: data,
                },
            });
            window.dispatchEvent(event);
        });

        Livewire.on('showDynamicFilters', (count) => {
            $('#products-filters-toggler').show();
        });

        $("#offcanvasFilters").on('click', '#color-attributes input', function () {
            $(this).closest('ul').find('input:checkbox').not(this).prop('checked', false);
        })
        $("#offcanvasFilters").on('click', '#width-attributes input', function () {
            $(this).closest('ul').find('input:checkbox').not(this).prop('checked', false);
        })
        $("#offcanvasFilters").on('click', '#finish-attributes input', function () {
            $(this).closest('ul').find('input:checkbox').not(this).prop('checked', false);
        })
        $("#offcanvasFilters").on('click', '#version-attributes input', function () {
            $(this).closest('ul').find('input:checkbox').not(this).prop('checked', false);
        })
    });

    const productListPage = document.querySelector('body.products');
    if (!productListPage) return;

    // Read more
    const p = document.getElementById('content-read-more');
    const btn = document.getElementById('read-more-button');

    if (p && btn) {
        p.style.webkitLineClamp = p.getAttribute('data-line');
        btn.addEventListener('click', () => {
            if (p.style.webkitLineClamp !== 'unset') {
                p.style.webkitLineClamp = 'unset';
                btn.innerText = p.getAttribute('data-label-less');
            } else {
                p.style.webkitLineClamp = p.getAttribute('data-line');
                btn.innerText = p.getAttribute('data-label-more');
            }
        })
    }

    // Categories carousel
    const container = document.querySelector('.container');
    const categoryCarousel = document.querySelector('.categories__item:first-of-type');
    const categoryCarouselInner = document.querySelector('.categories__inner');
    const categories = document.querySelectorAll('.categories__item');

    if (matchMediaXlUp.matches) categories?.forEach(item => item.classList.remove('order-1'));
    if (categoryCarousel && categoryCarouselInner) {
        const resizeCarousel = () => {
            categoryCarousel.style.marginLeft = container.getBoundingClientRect().left + 'px';
            categoryCarouselInner.style.opacity = 1;
        };
        resizeCarousel()

        const onResize = debounce((timeout = 300) => resizeCarousel());
        window.addEventListener("resize", onResize);
    }
}
