import {Popover, Modal, Tabs} from "bootstrap";
import jQuery from 'jquery';
import Lenis from '@studio-freight/lenis'; // Smooth scroll
import lozad from 'lozad'; // Lazy load
import imagesLoaded from 'imagesloaded';
import mainNavHandler from "./mainNavHandler";
import scrollObserver from "./scrollObserver";
import InfiniteMarquee from 'vanilla-infinite-marquee';
import {debounce} from "./debounce";
import SimpleLightbox from "simplelightbox";
import {
    countrySelect,
    modalProductCode,
    modalProductType,
    selectCity,
    selectCity_2,
    selectCity_3,
    selectCountry_2,
    selectCountry_3,
    selectCountry_4,
    selectProvince,
    selectProvince_2,
    selectProvince_3,
    selectRequestType,
    selectUserType,
} from "./selects";
import productList from "./products/list";
import productDetail from "./products/detail";
import {OverlayScrollbars} from 'overlayscrollbars';
import Core from "./core";
import register from "./auth/register";
import auth from "./auth/auth";
import {matchMediaLGUp, matchMediaXlDown, matchMediaXlUp} from "./mediaQuery";
import {StoreLocator} from "./storeLocator";
import editProfile from "./auth/edit-profile";
import analyticsEventPusher from "./analytics-event-pusher";
import downloads from "./downloads";
import reservedAreaVideo from "./auth/video";

window.$ = window.jQuery = jQuery;

/* Constants */
const mainHeader = document.querySelector('#main-header');
const mainHeaderNavbar = mainHeader.querySelector('.navbar');
const mainNav = document.querySelector('#main-nav');
const mainNavLinks = mainHeaderNavbar.querySelectorAll('.nav-link--main');

(function ($) {
    Core.token();
    analyticsEventPusher();

    /**
     * Smooth scroll
     * @type {Lenis}
     */
    if (!document.body.classList.contains('no-smooth-scroll')) {
        const lenis = new Lenis();

        /*lenis.on('scroll', (e) => {
            console.log(e)
        })*/
        function raf(time) {
            lenis.raf(time)
            requestAnimationFrame(raf)
        }

        requestAnimationFrame(raf)
        // Handle Language Modal scroll
        const languagesModal = document.getElementById('languagesModal');
        if (languagesModal) {
            languagesModal.addEventListener('shown.bs.modal', () => {
                lenis.stop();
            });
            languagesModal.addEventListener('hidden.bs.modal', () => {
                lenis.start();
            });
        }
        // Handle Filter Offcanvas scroll
        const offcanvasFilters = document.getElementById('offcanvasFilters');
        if (offcanvasFilters) {
            offcanvasFilters.addEventListener('shown.bs.offcanvas', () => {
                lenis.stop();
            });
            offcanvasFilters.addEventListener('hidden.bs.offcanvas', () => {
                lenis.start();
            });
        }

        const code12NCModal = document.getElementById('code12NCModal');
        if (code12NCModal) {
            code12NCModal.addEventListener('shown.bs.modal', () => {
                lenis.stop();
            });
            code12NCModal.addEventListener('hidden.bs.modal', () => {
                lenis.start();
            });
        }
    }

    /**
     * Force page to load on top
     */
    if (history.scrollRestoration) {
        history.scrollRestoration = 'manual';
    } else {
        window.onbeforeunload = function () {
            window.scroll(0, 0);
        }
    }

    /**
     * Detect js disabled
     */
    if (document.body.classList.contains('no-js')) {
        document.body.classList.remove('no-js');
    }

    /**
     * Main Nav Handler
     */
    document.addEventListener("DOMContentLoaded", (event) => {
        if (mainNav) {
            mainNavHandler(mainNav, mainNavLinks)
        }
    });

    /**
     * Close button
     * @type {NodeListOf<Element>}
     */
    const btnCloseItems = document.querySelectorAll('.btn-close');
    if (btnCloseItems.length) {
        btnCloseItems.forEach(btnClose => {
            btnClose.addEventListener('click', (e) => {
                e.preventDefault();
                const btnTargetSelector = e.currentTarget.dataset.target;
                if (btnTargetSelector) {
                    const btnTarget = document.querySelector(btnTargetSelector);
                    btnTarget.classList.remove('show');
                    if (btnTargetSelector === '#main-nav') {
                        document.body.classList.remove('main-nav-shown');
                    }
                }
            });
        });
    }

    /**
     * Detect scroll direction
     * @returns {boolean}
     */
    let lastScrollTop = 0;
    const isScrollingDown = () => {
        let goingDown = false;
        let st = window.scrollY || document.documentElement.scrollTop;
        goingDown = st > lastScrollTop;
        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
        return goingDown;
    };

    /**
     * Toggle scroll direction body and navbar theme classes
     * Math.sign(window.scrollY) !== -1 for detecting Safari negative over-scroll
     */
    const handleBodyClassByScroll = () => {
        if (!document.body.classList.contains('mega-dropdown-shown')) {
            if (isScrollingDown()) {
                document.body.classList.remove("scroll-up");
                document.body.classList.add("scroll-down");
            } else if (Math.sign(window.scrollY) !== -1) {
                document.body.classList.add("scroll-up");
                document.body.classList.remove("scroll-down");
                mainHeaderNavbar.classList.replace('navbar-dark', 'navbar-light')
            }
            if (window.scrollY < 50 && Math.sign(window.scrollY) !== -1) {
                document.body.classList.remove("scroll-up");
                mainHeaderNavbar.classList.remove('navbar-dark', 'navbar-light');
                if (mainHeaderNavbar.dataset.theme) {
                    mainHeaderNavbar.classList.add(mainHeaderNavbar.dataset.theme);
                }
            }
        }
    };
    window.addEventListener("scroll", handleBodyClassByScroll);

    /**
     * Cappe Carousel
     * @type {exports|HTMLElement|*}
     */
    const cappeCarouselItems = document.querySelectorAll('.cappe-carousel');
    if (cappeCarouselItems.length) {
        import('./carousels').then(module => {
            module.cappeCarousel(cappeCarouselItems);
        });
    }

    /**
     * Products Suggested Carousel
     * @type {exports|HTMLElement|*}
     */
    const productsSuggestedCarouselItems = document.querySelectorAll('.products-suggested-carousel');
    if (productsSuggestedCarouselItems.length) {
        import('./carousels').then(module => {
            module.productsSuggestedCarousel(productsSuggestedCarouselItems);
        });
    }

    /**
     * Categories App Functions Carousel
     * @type {exports|HTMLElement|*}
     */
    const appFunctionsCarouselItems = document.querySelectorAll('.app-functions-carousel');
    if (appFunctionsCarouselItems.length) {
        if (appFunctionsCarouselItems.length) {
            import('./carousels').then(module => {
                module.appFunctionsCarousel(appFunctionsCarouselItems);
            });
        }
    }

    /**
     * Categories Carousel
     * @type {exports|HTMLElement|*}
     */
    const categoriesCarouselItems = document.querySelectorAll('.categories-carousel');
    if (categoriesCarouselItems.length) {
        // categoriesCarousel(categoriesCarouselItems);
        import('./carousels').then(module => {
            module.categoriesCarousel(categoriesCarouselItems);
        });
    }

    /**
     * Accessories Carousel
     * @type {exports|HTMLElement|*}
     */
    const accessoriesCarouselItems = document.querySelectorAll('.accessories-carousel');
    if (accessoriesCarouselItems.length) {
        // accessoriesCarousel(accessoriesCarouselItems);
        import('./carousels').then(module => {
            module.accessoriesCarousel(accessoriesCarouselItems);
        });
    }

    /**
     * Service Carousel
     * @type {exports|HTMLElement|*}
     */
    const serviceCarouselItems = document.querySelectorAll('.service-carousel');
    if (serviceCarouselItems.length) {
        import('./carousels').then(module => {
            serviceCarouselItems.forEach(serviceCarouselItem => {
                module.serviceCarousel(serviceCarouselItem);
            })
        });
    }

    /**
     * Certificate Carousel
     * @type {exports|HTMLElement|*}
     */
    const certificateCarouselItems = document.querySelectorAll('.certificate-carousel');
    if (certificateCarouselItems) {
        import('./carousels').then(module => {
            certificateCarouselItems.forEach(certificateCarouselItem => {
                module.certificateCarousel(certificateCarouselItem);
            })
        });
    }

    /**
     * Categories Filter Carousel
     * @type {exports|HTMLElement|*}
     */
    const categoriesFilterCarouselItems = document.querySelectorAll('.categories-filter-carousel');
    if (categoriesFilterCarouselItems.length) {
        import('./carousels').then(module => {
            categoriesFilterCarouselItems.forEach(categoriesFilterCarouselItem => {
                module.categoriesFilterCarousel(categoriesFilterCarouselItem);
            })
        });
    }

    /**
     * Product Hero Carousel
     * @type {exports|HTMLElement|*}
     */
    const productHeroCarouselItems = document.querySelectorAll('.product-hero-carousel');
    if (productHeroCarouselItems.length) {
        import('./carousels').then(module => {
            productHeroCarouselItems.forEach(productHeroCarouselItem => {
                module.productHeroCarousel(productHeroCarouselItem);
            })
        });
    }

    /**
     * Wide Carousel (with Lightbox)
     * @type {exports|HTMLElement|*}
     */
    const wideCarouselItems = document.querySelectorAll('.wide-carousel');
    if (wideCarouselItems.length) {
        import('./carousels').then(module => {
            wideCarouselItems.forEach(wideCarouselItem => {
                module.wideCarousel(wideCarouselItem);
            })
        });
    }

    /**
     * Wall Carousel (innovation)
     * @type {exports|HTMLElement|*}
     */
    const wallCarouselItems = document.querySelectorAll('.wall-carousel');
    if (wallCarouselItems.length) {
        import('./carousels').then(module => {
            wallCarouselItems.forEach(wallCarouselItem => {
                module.wallCarousel(wallCarouselItem);
            })
        });
    }

    /**
     * Single Slide Carousel (with Lightbox)
     * @type {exports|HTMLElement|*}
     */
    const singleSlideCarouselItems = document.querySelectorAll('.single-slide-carousel');
    if (singleSlideCarouselItems.length) {
        import('./carousels').then(module => {
            singleSlideCarouselItems.forEach(singleSlideCarouselItem => {
                module.singleSlideCarousel(singleSlideCarouselItem);
            })
        });

        /**
         * Slick reloading after livewire calls
         */
        document.addEventListener('livewire:init', () => {
            Livewire.on('reload-slick', (event) => {
                setTimeout(() => {
                    const singleSlideCarousel = $('.single-slide-carousel');
                    if (singleSlideCarousel) {
                        singleSlideCarousel.slick('unslick');
                        singleSlideCarousel.slick({
                            arrows: false,
                            dots: true,
                            speed: 500,
                            cssEase: 'linear',
                            adaptiveHeight: true
                        });
                    }
                }, 600)
            });
        });
    }

    /**
     * Single Slide Carousel (with Lightbox)
     * @type {exports|HTMLElement|*}
     */
    const hoodChoiceCarouselItems = document.querySelectorAll('.hood-choice-carousel-nav');
    if (hoodChoiceCarouselItems.length) {
        import('./carousels').then(module => {
            hoodChoiceCarouselItems.forEach(hoodChoiceCarouselItem => {
                module.hoodChoiceCarousel(hoodChoiceCarouselItem);
            })
        });
    }

    /**
     * Choose Cappe Carousel
     * @type {exports|HTMLElement|*}
     */
    const materialCappaCarouselItems = document.querySelectorAll('.material-cappa-carousel');
    if (materialCappaCarouselItems.length) {
        import('./carousels').then(module => {
            module.materialCappaCarousel(materialCappaCarouselItems);
        });
    }

    // /**
    //  * Choose Lhov installazione Carousel
    //  * @type {exports|HTMLElement|*}
    //  */
    // const lhovInstallationCarouselItems = document.querySelectorAll('.installation-slider');
    // if (lhovInstallationCarouselItems.length) {
    //     import('./carousels').then(module => {
    //         module.lhovInstallationCarousel(lhovInstallationCarouselItems);
    //     });
    // }

    /**
     * Prizes Carousel
     * @type {exports|HTMLElement|*}
     */
    const prizesCarouselItems = document.querySelectorAll('.prizes-carousel');
    if (prizesCarouselItems.length) {
        import('./carousels').then(module => {
            module.prizesCarousel(prizesCarouselItems);
        });
    }

    /**
     * Lazy load
     */
    const lazyItems = document.querySelectorAll('.lazy');
    if (lazyItems.length) {
        let options = {
            loaded: function (el) {
                imagesLoaded(el, function () {
                    el.parentNode.classList.add('media-loaded');
                });
                if (!el.alt && el.dataset.alt) {
                    el.alt = el.dataset.alt;
                }
            }
        };
        const observer = lozad(lazyItems, options);
        observer.observe();
    }

    /**
     * Animated banners
     */
    const animatedBannersV1 = document.querySelectorAll('.banner-v1 .animated-banner');
    if (animatedBannersV1.length) {
        scrollObserver({items: animatedBannersV1});
    }

    const animatedBannersV1Texts = document.querySelectorAll('.banner-v1 .animated-banner-text');
    if (animatedBannersV1Texts.length) {
        scrollObserver({items: animatedBannersV1Texts, targetClass: "fadeIn"});
    }

    const animatedBannersV2 = document.querySelectorAll('.banner-v2 .animated-banner');
    if (animatedBannersV2.length) {
        const rootMargin = "-250px 0px";
        scrollObserver({items: animatedBannersV2, rootMargin: rootMargin});
    }

    const sectionVocalAssistantSections = document.querySelectorAll('.section-vocal-assistant');
    if (sectionVocalAssistantSections) {
        const rootMargin = "-250px 0px";
        scrollObserver({items: sectionVocalAssistantSections, rootMargin: rootMargin});
    }


    /**
     * Carousel technical specifications
     * @type {NodeListOf<Element>}
     */
    const carouselAdaptiveHeight = document.querySelectorAll('.carousel-adaptive-height');
    if (carouselAdaptiveHeight.length) {
        scrollObserver({items: carouselAdaptiveHeight});
    }

    /**
     * Stop video playing on viewport leave
     * @type {NodeListOf<Element>}
     */
    const videoItems = document.querySelectorAll('video.pause-onLeave');
    if (videoItems.length) {
        scrollObserver({items: videoItems})
    }

    /**
     * Marquees
     * @type {NodeListOf<Element>}
     */
    const marquees = document.querySelectorAll(".marquee--default");
    if (marquees.length) {
        const marqueeObject = new InfiniteMarquee({
            element: '.marquee--default .marquee__inner',
            speed: 45000,
            smoothEdges: false,
            pauseOnHover: false,
            direction: 'left',
            spaceBetween: '24px',
            duplicateCount: 1,
            mobileSettings: {
                speed: 50000
            },
        });
        if (marqueeObject.element.length) {
            scrollObserver({items: marqueeObject.element, rootMargin: "0px 0px", threshold: 0})
        }
        const marqueePauseOnHoverItems = document.querySelectorAll('.marquee__inner[data-pause-hover="true"]');
        if (marqueePauseOnHoverItems.length) {
            marqueePauseOnHoverItems.forEach(marqueePauseOnHoverItem => {
                const anchors = marqueePauseOnHoverItem.querySelectorAll('a');
                if (anchors.length) {
                    anchors.forEach(anchor => {
                        anchor.addEventListener('mouseenter', (e) => {
                            marqueePauseOnHoverItem.classList.add('paused');
                        });
                        anchor.addEventListener('mouseleave', (e) => {
                            marqueePauseOnHoverItem.classList.remove('paused');
                        });
                    });
                }
            });
        }
    }

    const marqueesBannerV2 = document.querySelectorAll(".marquee--banner-v2");
    if (marqueesBannerV2.length) {
        const marqueeObject = new InfiniteMarquee({
            element: '.marquee--banner-v2 .marquee__inner',
            speed: 15000,
            smoothEdges: false,
            pauseOnHover: false,
            direction: 'left',
            spaceBetween: '24px',
            duplicateCount: 2,
            mobileSettings: {
                speed: 18000
            },
        });
        if (marqueeObject.element.length) {
            scrollObserver({items: marqueeObject.element, rootMargin: "0px 0px", threshold: 0})
        }
    }

    /**
     * Vertical Marquee Carousel
     * @type {exports|HTMLElement|*}
     */
    const verticalMarqueeCarouselItems = document.querySelectorAll('.vertical-marquee');
    if (verticalMarqueeCarouselItems.length) {
        import('./carousels').then(module => {
            module.verticalMarqueeCarousel(verticalMarqueeCarouselItems);
        });
    }

    /**
     * Type effect
     * @type {NodeListOf<Element>}
     */
    const typeEffectItems = document.querySelectorAll('.type-effect');
    if (typeEffectItems.length && matchMediaLGUp.matches) {
        import('typeit').then(module => {
            const typeIt = new module.default(".type-effect", {
                waitUntilVisible: true,
                speed: 50,
                cursor: false,
            }).go();
        });
    }

    /**
     * Products Grid Layout Togglers
     * @type {NodeListOf<Element>}
     */
    const productsGridLayoutTogglers = document.querySelectorAll('.products-grid-layout-toggler');
    const productsGrid = document.querySelector('.products-grid');
    if (productsGridLayoutTogglers.length && productsGrid) {
        productsGridLayoutTogglers.forEach(productsGridLayoutToggler => {
            productsGridLayoutToggler.addEventListener('click', (e) => {
                e.preventDefault();
                const target = e.currentTarget;
                const cols = target.dataset.cols;
                const classPrefix = 'products-grid--cols-';
                const colsSelector = classPrefix + cols;
                // Remove all active items
                productsGridLayoutTogglers.forEach(toggler => toggler.classList.remove('active'));
                // Set active class
                target.classList.add('active');
                // Remove all classes start with classPrefix
                productsGrid.classList.remove(...Array.from(productsGrid.classList).filter(v => v.startsWith(classPrefix)));
                // Add products grid layout class
                productsGrid.classList.add(colsSelector);
                //
                setProductsGridVideoCardHeight();
            }, {capture: true});
        });
    }

    /**
     * Popovers
     * @type {NodeListOf<Element>}
     */
    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
    if (popoverTriggerList.length) {
        const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new Popover(popoverTriggerEl))
    }

    /**
     *  Set Products Grid Card with Video height
     */
    const setProductsGridVideoCardHeight = () => {
        const productCardWithVideoItems = document.querySelectorAll('.product-card.has-video');
        if (productCardWithVideoItems.length) {
            const productCardStandardItems = document.querySelectorAll('.product-card[data-highlight="false"]:not(.has-video)');
            if (productCardStandardItems.length) {
                setTimeout(() => {
                    productCardWithVideoItems.forEach(productCardWithVideoItem => {
                        productCardWithVideoItem.style.height = `${productCardStandardItems[0].clientHeight}px`;
                    });
                }, 600);
            }
        }
    }

    /**
     * Lightbox
     */
    const lightboxes = document.querySelectorAll('.lightbox');
    if (lightboxes.length) {
        lightboxes.forEach(lightbox => {
            const lightboxAnchors = lightbox.querySelectorAll('a');
            if (lightboxAnchors.length) {
                new SimpleLightbox(lightboxAnchors, {
                    fadeSpeed: 600,
                    navText: ['<span class="icon icon--xl icon--chevron-left filter-invert-1"></span>', '<span class="icon icon--xl icon--chevron-right filter-invert-1"></span>'],
                    closeText: '<span class="icon icon--xl icon--close filter-invert-1"></span>'
                });
            }
        })
    }

    /**
     * Lightbox cta
     */
    const lightboxesCta = document.querySelectorAll('.lightbox-cta');

    if (lightboxesCta.length) {
        lightboxesCta.forEach(lightboxCta => {
            const lightboxCtaAnchors = lightboxCta.querySelectorAll('a');
            if (lightboxCtaAnchors.length) {
                const singlelightboxCta = new SimpleLightbox(lightboxCtaAnchors, {
                    fadeSpeed: 600,
                    navText: ['<span class="icon icon--xl icon--chevron-left filter-invert-1"></span>', '<span class="icon icon--xl icon--chevron-right filter-invert-1"></span>'],
                    closeText: '<span class="icon icon--xl icon--close filter-invert-1"></span>',
                    captionsData: 'alt',
                    additionalHtml: '<div class="text-center pt-4 lightboxBtnCtaContainer"><a href="#" class="btn btm-sm py-2 btn-outline-light lightboxBtnCta" style="display: none;">Discover</a></div>',
                });

                const handleCta = () => {
                    let currentImageIndex = singlelightboxCta.getLighboxData().currentImageIndex.toString();
                    Array.from(lightboxCtaAnchors).forEach((el) => {
                        const urlCta = el.getAttribute('data-cta');
                        const activeItemGallery = el.getAttribute('data-index');

                        if (activeItemGallery === currentImageIndex) {
                            const lightboxBtnCta = document.querySelector('.lightboxBtnCtaContainer .lightboxBtnCta');
                            if (urlCta !== "javascript:;" && urlCta !== "#" && typeof urlCta === "string") {
                                lightboxBtnCta.style.display = "inline-block";
                                lightboxBtnCta.href = urlCta;
                            } else {
                                lightboxBtnCta.style.display = "none";
                            }
                        }

                    });
                }

                singlelightboxCta.on('changed.simplelightbox', function () {
                    handleCta();
                });

                singlelightboxCta.on('shown.simplelightbox', function () {
                    handleCta();
                });
            }
        })
    }

    /**
     * Handle resize
     * @param callbacks
     */
    function handleOnResize(callbacks) {
        if (!callbacks || callbacks.length === 0) {
            return;
        }
        callbacks.forEach(callback => callback());
    }

    let onResizeCallbacks = [setProductsGridVideoCardHeight];
    // on page load
    handleOnResize(onResizeCallbacks);
    // on page resize
    const onResize = debounce((timeout = 300) => handleOnResize(onResizeCallbacks));
    window.addEventListener("resize", onResize);

    // Sticky navbar (for @enrico)
    // TODO : better solution using .has-sticky
    const stickyNavbar = document.querySelector('.sticky-navbar');
    if (stickyNavbar) {
        document.addEventListener('scroll', () => {
            if (stickyNavbar.getBoundingClientRect()?.top === 0) {
                if (!stickyNavbar.classList.contains('active')) {
                    stickyNavbar.classList.add('active')
                }
            } else {
                stickyNavbar.classList.remove('active')
            }
        });
    }

    /**
     * Set active class for position sticky items
     * @type {Element}
     */
    const hasSticky = document.querySelector('.has-sticky');
    if (hasSticky) {
        document.addEventListener('scroll', () => {
            if (hasSticky.getBoundingClientRect()?.top === 0) {
                if (!hasSticky.classList.contains('active')) {
                    hasSticky.classList.add('active')
                }
            } else {
                hasSticky.classList.remove('active')
            }
        });
    }

    /**
     * Product Width Togglers
     * @type {NodeListOf<Element>}
     */
    const productWidthTogglers = document.querySelectorAll('.product-width-toggler');
    if (productWidthTogglers.length) {
        productWidthTogglers.forEach(productWidthToggler => {
            productWidthToggler.addEventListener('click', (e) => {
                e.preventDefault();
                productWidthToggler.parentNode.querySelector('.active')?.classList.remove('active');
                e.currentTarget.classList.add('active');
            })
        });
    }

    /**
     * Toggle Icon class (dropdown shown|collapsed)
     * @param trigger
     */
    const collapseToggleIconClass = (trigger) => {
        const icon = trigger.querySelector('.icon');
        icon.classList.toggle('dropdown-shown');
    }

    /**
     * Mobile Collapse Anchors Nav
     * @type {HTMLElement}
     */
    const collapseAnchorsNav = document.getElementById('collapseAnchorsNav');
    if (collapseAnchorsNav) {
        collapseAnchorsNav.addEventListener('show.bs.collapse', function (e) {
            const previousSibling = e.currentTarget.previousElementSibling;
            if (previousSibling && previousSibling.getAttribute('type') === "button") {
                collapseToggleIconClass(previousSibling);
            }
        });
        collapseAnchorsNav.addEventListener('hide.bs.collapse', function (e) {
            const previousSibling = e.currentTarget.previousElementSibling;
            if (previousSibling && previousSibling.getAttribute('type') === "button") {
                collapseToggleIconClass(previousSibling);
            }
        });
    }

    /**
     * Banner v2 Iframe Handler
     * @type {NodeListOf<Element>}
     */
    const bannerV2 = document.querySelector('.banner-v2');
    if (bannerV2) {
        const bannerVideoModal = bannerV2.querySelector('#bannerVideoModal');
        const desktopVideo = bannerV2.querySelector('.banner-v2__video--desktop');
        const mobileVideo = bannerV2.querySelector('.banner-v2__video--mobile');
        if (bannerVideoModal) {
            bannerVideoModal.addEventListener('shown.bs.modal', (e) => {
                const iframe = e.currentTarget.querySelector('iframe');
                if (
                    iframe
                    && !iframe.hasAttribute('src')
                    && iframe.getAttribute('data-src')
                ) {
                    // Reload iframe
                    const dataSrc = iframe.getAttribute('data-src');
                    iframe.setAttribute('src', dataSrc);
                    // Play iframe
                    setTimeout(() => {
                        let player = new Vimeo.Player(iframe);
                        player.play();
                    }, 600)
                }
                // Pause desktop background video
                if (matchMediaXlUp.matches && desktopVideo) {
                    desktopVideo.pause();
                }
                // Pause mobile background video
                if (matchMediaXlDown.matches && mobileVideo) {
                    mobileVideo.pause();
                }
            });
            bannerVideoModal.addEventListener('hidden.bs.modal', (e) => {
                const iframe = e.currentTarget.querySelector('iframe');
                // Stop iframe playing
                iframe.removeAttribute('src');
                // Restart desktop background video
                if (matchMediaXlUp.matches && desktopVideo) {
                    desktopVideo.play();
                }
                // Restart mobile background video
                if (matchMediaXlDown.matches && mobileVideo) {
                    mobileVideo.play();
                }
            });
        }
    }

    /**
     * Vimeo Iframe
     * @type {NodeListOf<Element>}
     */
    const vimeoIframes = document.querySelectorAll('.vimeo-iframe');
    if (vimeoIframes.length) {
        const vimeoPlayerEndPoint = "//player.vimeo.com/api/player.js";
        fetch(vimeoPlayerEndPoint).then(data => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = vimeoPlayerEndPoint;
            script.setAttribute('data-cookieconsent', 'ignore');
            document.body.appendChild(script);
            // script.onload = function() {console.log('Vimeo API loaded!');};
        }).catch(error => {
            console.error(error);
        })
    }

    /**
     * Iframe Video Modal (BS5) Handler
     * @type {Element}
     */
    const iframeVideoModals = document.querySelectorAll('.iframe-video-modal');
    if (iframeVideoModals.length) {
        iframeVideoModals.forEach(iframeVideoModal => {
            iframeVideoModal.addEventListener('shown.bs.modal', (e) => {
                const iframe = e.currentTarget.querySelector('iframe');
                if (
                    iframe
                    && !iframe.hasAttribute('src')
                    && iframe.getAttribute('data-src')
                ) {
                    // Reload iframe
                    const dataSrc = iframe.getAttribute('data-src');
                    iframe.setAttribute('src', dataSrc);
                    // Play iframe
                    setTimeout(() => {
                        let player = new Vimeo.Player(iframe);
                        player.play();
                    }, 600)
                }
            });
            iframeVideoModal.addEventListener('hidden.bs.modal', (e) => {
                const iframe = e.currentTarget.querySelector('iframe');
                // Stop iframe playing
                iframe.removeAttribute('src');
            });
        })
    }

    /**
     * Selects 2
     * @type {exports|HTMLElement|*}
     */
    const $countrySelect = $("#selectCountry");
    if ($countrySelect) {
        countrySelect($countrySelect);
    }

    const $selectCountry_2 = $("#selectCountry_2");
    if ($selectCountry_2) {
        selectCountry_2($selectCountry_2);
    }

    const $selectCountry_3 = $("#selectCountry_3");
    if ($selectCountry_3) {
        selectCountry_3($selectCountry_3);
    }

    const $selectCountry_4 = $("#selectCountry_4");
    if ($selectCountry_4) {
        selectCountry_4($selectCountry_4);
    }


    const $selectProvince = $("#selectProvince");
    if ($selectProvince) {
        selectProvince($selectProvince);
    }
    const $selectProvince_2 = $("#selectProvince_2");
    if ($selectProvince_2) {
        selectProvince_2($selectProvince_2);
    }
    const $selectProvince_3 = $("#selectProvince_3");
    if ($selectProvince_3) {
        selectProvince_3($selectProvince_3);
    }

    const $selectCity = $("#selectCity");
    if ($selectCity) {
        selectCity($selectCity);
    }

    const $selectCity_2 = $("#selectCity_2");
    if ($selectCity_2) {
        selectCity_2($selectCity_2);
    }

    const $selectCity_3 = $("#selectCity_3");
    if ($selectCity_3) {
        selectCity_3($selectCity_3);
    }

    const $selectUserType = $("#selectUserType");
    if ($selectUserType) {
        selectUserType($selectUserType);
    }

    const $selectRequestType = $("#selectRequestType");
    if ($selectRequestType) {
        selectRequestType($selectRequestType);
    }

    const $selectModalProductType = $("#selectModalProductType");
    if ($selectModalProductType) {
        modalProductType($selectModalProductType);
    }

    const $selectModalProductCode = $("#selectModalProductCode");
    if ($selectModalProductCode) {
        modalProductCode($selectModalProductCode);
    }

    /**
     * Store locator init
     * @type {Element}
     */
    const storeLocatorPage = document.querySelector('body.page-store-locator');
    if (storeLocatorPage) {
        StoreLocator.page();
    }

    /**
     * Parallax
     * @type {NodeListOf<Element>}
     */
    const parallaxes = document.querySelectorAll('.parallax');
    if (parallaxes && parallaxes.length) {
        scrollObserver({items: parallaxes, rootMargin: "100px 0px"});
    }

    /**
     * Carousel
     * @type {NodeListOf<Element>}
     */
    const carousel = document.querySelectorAll('.carousel.carousel-has-reveal');
    if (carousel && carousel.length) {
        scrollObserver({items: carousel, targetClass: "revealed"});
    }

    /**
     * Innovation page scripts
     * @type {Element}
     */
    const isInnovationPage = document.querySelector('body.innovation');
    if (isInnovationPage) {
        import('./landings/innovation').then(module => {
            module.default();
        });
    }

    /**
     * Design page scripts
     * @type {Element}
     */
    const isDesignPage = document.querySelector('body.design');
    if (isDesignPage) {
        import('./landings/design').then(module => {
            module.default();
        });
    }

    /**
     * Custom Article page scripts
     * @type {Element}
     */
    const isCustomArticlePage = document.querySelector('body.custom-article');
    if (isCustomArticlePage) {
        import('./articles/customArticle').then(module => {
            const arrayPortataCappe = ["100", "200", "300", "400", "500", "600", "700"];
            module.displayRangeValues("rangePortataCappa", "rangePortataValue", arrayPortataCappe, "labelRangeContainer");
            module.gallerySyncTabs('kitchenLightsTab', 'gallerySyncTabsContainer');
            module.gallerySyncTabs('kitchenLightsTab', 'gallerySyncTabsContainerMobile');
        });
    }

    /**
     * Art page scripts
     * @type {Element}
     */
    const isArtPage = document.querySelector('body.art');
    if (isArtPage) {
        import('./landings/art').then(module => {
            module.default();
        });
    }

    /**
     * Lhov installazione page scripts
     * @type {Element}
     */
    const islhovInstallationPage = document.querySelector('body.lhov-installazione');
    if (islhovInstallationPage) {
        import('./landings/lhov-installazione').then(module => {
            module.default();
        });
    }

    /**
     * Story page scripts
     * @type {Element}
     */
    const isStoryPage = document.querySelector('body.story');
    if (isStoryPage) {
        import('./landings/story').then(module => {
            module.default();
        });
    }

    if (document.querySelector('body.area-riservata-configuratore')) {
        import('./auth/configurator').then(module => {
            module.default();
        });
    }

    // validation form bootstrap
    const forms = document.querySelectorAll('.needs-validation')

    Array.from(forms).forEach(form => {
        form.addEventListener('submit', event => {
            if (!form.checkValidity()) {
                event.preventDefault()
                event.stopPropagation()
            }

            form.classList.add('was-validated')
        }, false)
    });

    /**
     * Read more
     * @type {NodeListOf<Element>}
     */
        // Read more
    const jsReadMoreItems = document.querySelectorAll('.js-read-more');
    if (jsReadMoreItems && jsReadMoreItems.length) {
        import('./readMore').then(module => {
            module.ReadMore.init();
        });
    }

    /**
     * Animation text hero
     */
    window.addEventListener('load', () => {
        const heroAnimationText = function () {
            const heroes = document.querySelectorAll('.hero-text-animated');
            if (!heroes.length) return;

            heroes.forEach(hero => {
                const heroChildren = document.querySelectorAll('.hero-text-animated > *');
                heroChildren.forEach((el, i) => {
                    el.style.opacity = 0;
                    setTimeout(() => {
                        el.style.opacity = 1;
                        el.style.transform = "translateY(0)";
                    }, 400 * i)
                })
            })
        }
        heroAnimationText()
    })

    /**
     * Compasso Modal
     */
    window.addEventListener('load', () => {
        const compassoModal = document.querySelector('#compassoModal');
        if (compassoModal && !sessionStorage.getItem('shown-compass-modal')) {
            const bsCompassoModal = new Modal(compassoModal);
            setTimeout(() => {
                bsCompassoModal.show();
            }, 2000);
            sessionStorage.setItem('shown-compass-modal', 'true');
        }
    });
})(jQuery);

productList();
productDetail();
downloads();
auth();

if (document.querySelector('body.video-area-riservata')) {
    reservedAreaVideo();
}

if (document.querySelector('body.register')) {
    register();
}

if (document.querySelector('body.profile')) {
    editProfile();
}

//section with translate text mobile
function adjustTranslationText() {
    const translateContainers50 = document.querySelectorAll('.has-translation-text-50 .translate-chield');

    if (translateContainers50) {
        translateContainers50.forEach((translateElement) => {
            const translateContentTitle = translateElement.querySelector('.title');

            const halfTitleHeight = translateContentTitle.clientHeight / 2;

            translateElement.style.top = "-" + halfTitleHeight + "px";
        });
    }

    const translateContainers25 = document.querySelectorAll('.has-translation-text-25 .translate-chield');

    if (translateContainers25) {
        translateContainers25.forEach((translateElement) => {
            const translateContentTitle = translateElement.querySelector('.title');

            const quarterTitleHeight = translateContentTitle.clientHeight / 3.5;

            translateElement.style.top = "-" + quarterTitleHeight + "px";
        });
    }
}

adjustTranslationText();
window.addEventListener('resize', adjustTranslationText);

