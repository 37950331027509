import jQuery from "jquery";

const Core = {
    token: function () {
        return jQuery.ajax({
            type: 'GET',
            url: window.token + "?time=" + (Date.now() / 1000 | 0),
            data: {},
            success(result) {
                jQuery.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': result
                    }
                });

                jQuery('meta[name="csrf-token"]').attr('content', result);

                if (jQuery('input[name=_token]').length) {
                    jQuery('input[name=_token]').val(result);
                }

                setTimeout(Core.token, 1000 * 60);
            },
            error() {
            }
        });
    },

}

export default Core;
