import {matchMediaXlDown} from "./mediaQuery";

/**
 * Main Nav handler (desktop & mobile)
 * @type {NodeListOf<Element>}
 */
const mainNavHandler = (mainNav, mainNavLinks) => {
    const getMegaDropdown = (item) => {
        return item.parentNode.querySelector('.mega-dropdown');
    }
    const closeMegaDropdown = () => {
        document.body.classList.remove('mega-dropdown-shown');
        mainNav.querySelector('.nav-link--main.active')?.classList.remove('active');
        mainNav.querySelector('.mega-dropdown.show')?.classList.remove('show');
    }
    mainNavLinks.forEach(mainNavLink => {
        /**
         * Handle mouseenter for desktop
         */
        mainNavLink.addEventListener('mouseenter', (e) => {
            const target = e.currentTarget;
            if (!target.classList.contains('has-children')) {
                closeMegaDropdown();
            } else {
                const mainNavLinkActive = mainNav.querySelector('.nav-link--main.active');
                if (mainNavLinkActive) {
                    mainNavLinkActive.classList.remove('active');
                    if (getMegaDropdown(mainNavLinkActive)) {
                        getMegaDropdown(mainNavLinkActive).classList.remove('show');
                    }
                }
                target.classList.add('active');
                if (getMegaDropdown(target)) {
                    getMegaDropdown(target).classList.add('show');
                    if (!document.body.classList.contains('mega-dropdown-shown')) {
                        document.body.classList.add('mega-dropdown-shown');
                    }
                }
                const navbarUnderlay = document.querySelector('.navbar-underlay');
                if (navbarUnderlay && getMegaDropdown(target)) {
                    navbarUnderlay.style.height = `${getMegaDropdown(target).offsetHeight}px`;
                }
            }
        });
        /**
         * Handle click for mobile
         */
        if (matchMediaXlDown.matches) {
            mainNavLink.addEventListener('click', (e) => {
                const target = e.currentTarget;
                if (getMegaDropdown(target) && target.classList.contains('has-children')) {
                    e.preventDefault();
                    getMegaDropdown(target).classList.add('show');
                }
            });
        }
    });
    mainNav.addEventListener('mouseleave', () => {
        closeMegaDropdown();
    });
    /**
     * Mobile Navbar Toggler
     * @type {Element}
     */
    const mobileNavbarToggler = document.querySelector('#mobile-navbar-toggler');
    if (mobileNavbarToggler) {
        mobileNavbarToggler.addEventListener('click', (e) => {
            e.preventDefault();
            const ariaExpanded = e.currentTarget.getAttribute('aria-expanded');
            ariaExpanded === "false" ?
                e.currentTarget.setAttribute('aria-expanded', 'true') :
                e.currentTarget.setAttribute('aria-expanded', 'false');
            const navTargetSelector = e.currentTarget.dataset.target;
            if (navTargetSelector) {
                const navTarget = document.querySelector(navTargetSelector);
                navTarget?.classList.toggle('show');
                if (navTargetSelector === '#main-nav') {
                    document.body.classList.add('main-nav-shown');
                }
            }
        });
    }
}

export default mainNavHandler;
